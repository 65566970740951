import { Counter, TextFill } from '@rumblefish/ui/Rumblefish23Theme';
import { StyledStack, NumbersStack, WhoWeAreSection } from './styles';
import { whoWeAreData } from './whoWeAre.data';
import type { WhoWeAreProps } from './WhoWeAre.types';
export const WhoWeAre = ({ ...props }: WhoWeAreProps) => {
  return (
    <WhoWeAreSection width="normal">
      <StyledStack {...props}>
        <TextFill nameBannerText="Who we are?">
          {whoWeAreData.textFillText}
        </TextFill>
        <NumbersStack>
          {whoWeAreData.counters.map((counter, index) => (
            <Counter
              key={index}
              to={counter.number}
              caption={counter.caption}
            />
          ))}
        </NumbersStack>
      </StyledStack>
    </WhoWeAreSection>
  );
};
