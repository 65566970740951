import { Stack, styled } from '@mui/material';

import { Section } from '@rumblefish/ui/Rumblefish23Theme';
export const WhoWeAreSection = styled(Section)(({ theme }) => ({
  contain: 'paint',
  borderBottom: `1px solid ${theme.palette.invertedColors?.PLUS_7}`,
  margin: theme.spacing(0, 0, 16, 0),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0, 0, 15, 0),
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0, 0, 9, 0),
  },
}));
export const StyledStack = styled(Stack)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const NumbersStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    rowGap: theme.spacing(2),
    margin: theme.spacing(0, 0, 2, 0),
  },

  div: {
    borderRightColor: theme.palette.invertedColors?.PLUS_7,
    borderRightStyle: 'solid',
    borderRightWidth: '1px',

    '&:last-child': {
      borderRight: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 50%',
      border: '0px',
      '&:nth-of-type(2n+1)': {
        borderRight: `1px solid ${theme.palette.invertedColors?.PLUS_7}`,
      },

      '&:last-child': {
        border: '0px',
      },
    },
  },
}));
